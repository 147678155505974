import * as React from 'react';
import AppBar from "../../../appbar/AppBar";
import {
    Button, Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    Snackbar,
    Tooltip,
    Typography,
    useTheme
} from "@material-ui/core";
import {SideBar} from "../../../sidebar/SideBar";
import {
    ArrowDownward, ArrowDropDown, ArrowDropUp,
    ArrowUpward,
    AssessmentOutlined,
    BatteryChargingFullOutlined,
    CloseRounded,
    ErrorOutlined,
    PowerSettingsNew,
    UpdateOutlined
} from "@material-ui/icons";
import {styled} from "@material-ui/core/styles";
import {BLACK, BLUE, LIGHTER_GREY, RED, WHITE} from "../../../../themes/colors";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {appContextActions, appContextSelector} from "../../../app-context/appContextSlice";
import {
    deviceContextActions,
    deviceContextSelector, deviceListOffsetSelector,
    getDevicesByBrand, getDevicesByBrandRefresh
} from "../../deviceSlice";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {AppWithFooter} from "../../../../App";
import {LocationState} from "../../../../@types/types";
import {
    CancelIconContainer,
    ErrorMessageSectionItem,
    FormTitle,
    HiddenOnMobileDiv,
    ScalableForm,
    ScalableFormControl,
    ScalableGrid,
    SuccessMessageSectionItem, UndeterminedCheckboxIcon
} from "../../../ui-components/StyledComponents";
import {calculatedFontSize, calculateResponsiveSizeWithMinMax} from "../../../../util/TextUtils";
import {selectLocationThunk} from "../../../app-context/thunks/SelectLocationThunk";
import {deviceTypeSelector, getDeviceTypes} from "../../deviceTypeSlice";
import {ServerMessage} from "../../../ui-components/ServerMessage";
import {useAppViewContext} from "../../../app-context/AppViewContainer";
import {
    DEV_STATUS_ACTIVE,
    DEV_STATUS_INACTIVE,
    DEV_STATUS_PENDING,
    DEVICE_STATUS,
    DeviceDto,
    getStatusDisplay,
    isActive,
    isFilterEqual,
    isInactive,
    DEVICE_ORDER_BY_TYPES
} from "../../../../dtos/Device";
import {ReportOption} from "../../../reports/ReportTypes";
import {selectBrandThunk} from "../../../app-context/thunks/SelectBrandThunk";
import {useCurrentUserContext} from "../../../app-context/CurrentUserContextContainer";
import {DEVICE_DASHBOARD} from "../../../../dtos/Report";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {LocationSelect} from "../../../ui-components/LocationSelect";
import ProgressBar from "../../../ui-components/ProgressBar";
import {InfiniteScrollList, InfiniteScrollProps} from "../../../ui-components/InfiniteScroll";
import DropdownMenu from "../../../ui-components/DropdownMenu";
import {ListOnItemsRenderedProps, ListOnScrollProps, VariableSizeList as List} from 'react-window';
import {getLocalizedString} from "../../../../util/Localization";
import {SAMS_BRANDS, WALMART_BRANDS} from "../../../../dtos/Brand";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";
import {ARCBadge} from "../../../ui-components/design-system/ARCBadge";
import {ARCSelectFilterInput} from "../../../ui-components/design-system/ARCSelectFilterInput";
import {ARCTextFilterInput} from "../../../ui-components/design-system/ARCTextFilterInput";
import "./ViewDevices.scss";
import {BulkDeviceAction} from "../../components/bulk-device-action/BulkDeviceAction";
import {ARCCheckbox} from "../../../ui-components/design-system/ARCCheckbox";

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, brandId} = useParams();

    const offSet = React.useRef(useAppSelector(deviceListOffsetSelector));

    const getDeviceData = React.useCallback(() => {
        if (offSet.current >= 0) {
            dispatch(getDevicesByBrand({
                locationId: context.currentLocation?.locationId ?? -1,
                brandId: context.currentBrand?.brandId
            }));
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch]);

    React.useEffect(() => {
        if (brandId && context.currentBrand?.brandId !== parseInt(brandId)) {
            dispatch(selectBrandThunk(parseInt(brandId)));
        }

        if (locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            getDeviceData();
        } else if (context.currentLocation?.locationId) {
            getDeviceData();
        }
    }, [brandId, context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch, getDeviceData, locationId]);
}

export const ViewDevices = () => {
    useAutoSelectLocationByUrl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const context = useAppSelector(appContextSelector);
    const appViewContext = useAppViewContext();
    const currentUserContext = useCurrentUserContext();
    const deviceContext = useAppSelector(deviceContextSelector);
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const {state} = useLocation() as LocationState;
    const theme = useTheme();
    const isSmOrBelow = useMediaQuery(theme.breakpoints.down('xs'));

    const [sortBy, setSortBy] = React.useState<DEVICE_ORDER_BY_TYPES | undefined>(deviceContext.deviceFilters.orderByString);
    const [order, setOrder] = React.useState<'asc' | 'desc'>(deviceContext.deviceFilters.isAsc ? 'asc' : 'desc');
    const sortedList = deviceContext.devicesList;
    const renderItemSize = 65;

    const ReportList : ReportOption[] = React.useMemo( () => {
        return [
            DEVICE_DASHBOARD
        ];
    }, [])

    const canEdit = React.useMemo(() => {
        return !currentUserContext.groups.isArcTechSupport && !currentUserContext.groups.isSSOHQUser;
    }, [currentUserContext.groups.isArcTechSupport, currentUserContext.groups.isSSOHQUser]);
    
    const [bulkDeviceMap, setBulkDeviceMap] = React.useState<{[deviceId: number] : boolean;}>({});
    const [showBulkMenu, setShowBulkMenu] = React.useState<boolean>(false);
    const bulkDeviceTypes = React.useMemo(() => {
        if (!context.currentLocation?.serialIsDevice) {
            return deviceTypeContext.deviceTypes; 
        }
        return deviceTypeContext.deviceTypes?.filter(dt => !dt.serialIsDevice) ?? [];
    }, [deviceTypeContext.deviceTypes, context.currentLocation?.serialIsDevice])
    
    const bulkEnabled = React.useMemo(() => {
        if (!appViewContext.brandView) {
            if (bulkDeviceTypes.length > 1) {
                return true;
            }
        }
        return false;
    }, [bulkDeviceTypes, appViewContext.brandView])
    
    const resetBulkDevice = React.useCallback(() => {
        setBulkDeviceMap({});
        dispatch(getDevicesByBrandRefresh({
            locationId: context.currentLocation?.locationId ?? -1,
            brandId: context.currentBrand?.brandId
        }));
    }, [dispatch, context.currentBrand?.brandId, context.currentLocation?.locationId])

    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);
    const [successOpen, setSuccessOpen] = React.useState<boolean>(true);

    type searchCriteriaType = {
        filters: {
            deviceTag: string | undefined,
            deviceIdNumber: string | undefined
        },
        status: DEVICE_STATUS | undefined
    }

    const DEFAULT_CRITERIA : searchCriteriaType = React.useMemo(() => {
        return {
            filters: {
                deviceTag: deviceContext.deviceFilters.deviceTag ?? '',
                deviceIdNumber: deviceContext.deviceFilters.deviceIdNumber ?? ''
            },
            status: deviceContext.deviceFilters.status,
        }
    }, [deviceContext.deviceFilters.deviceIdNumber, deviceContext.deviceFilters.deviceTag, deviceContext.deviceFilters.status]);

    const [searchCriteria, setSearchCriteria] = React.useState<searchCriteriaType>(DEFAULT_CRITERIA);

    React.useEffect(() => {
        setSearchCriteria(DEFAULT_CRITERIA);
        setSortBy(deviceContext.deviceFilters.orderByString);
        setOrder(deviceContext.deviceFilters.isAsc ? 'asc' : 'desc');
    }, [DEFAULT_CRITERIA, deviceContext.deviceFilters.isAsc, deviceContext.deviceFilters.orderByString]);

    const StatusFilterValue = React.useMemo(() => {
        if(appViewContext.brandView) {
            return '';
        } else if(!searchCriteria.status) {
            return '';
        } else {
            return searchCriteria.status;
        }
    }, [appViewContext.brandView, searchCriteria.status]);

    React.useEffect(() => {
        if(appViewContext.brandView) {
            setSearchCriteria(prevCriteria => ({
                ...prevCriteria,
                status: undefined
            }));
        }
    }, [appViewContext.brandView]);

    const maxActiveCount = React.useMemo(() => {
        if(deviceContext.totalLockers && deviceContext.lockersInReserve) {
            return deviceContext.totalLockers - deviceContext.lockersInReserve;
        } else {
            return 0;
        }
    }, [deviceContext.lockersInReserve, deviceContext.totalLockers]);

    const activeCount = deviceContext.totalActiveDevices ?? 0;

    const excessDeviceCount = React.useMemo(() => {
        const count = Math.abs(maxActiveCount - activeCount);

        return count > 0 ? count : 1;
    }, [activeCount, maxActiveCount]);

    // Gets the amount of devices needed to deactivate in order to make another active
    const numDevicesToDeactivate = React.useMemo(() => {
        const count = maxActiveCount - 1 - activeCount;
        return count < 0 ? Math.abs(count) : 0;
    }, [activeCount, maxActiveCount]);

    const isAtActiveDeviceLimit = React.useMemo(() => {
        return activeCount > 0 && activeCount === maxActiveCount;
    }, [activeCount, maxActiveCount]);

    const isOverActiveDeviceLimit = React.useMemo(() => {
        return activeCount > 0 && activeCount > maxActiveCount;
    }, [activeCount, maxActiveCount]);

    const getActiveLimitMessage = React.useMemo(() => {
        if(activeCount >= 0 && maxActiveCount >= 0) {
            const remaining = maxActiveCount - activeCount;
            if(remaining > 0) {
                return getLocalizedString('viewDevices.activeDeviceLimit.remaining', remaining + ' more ' + (remaining > 1 ? 'devices' : 'device') + ' can be activated', [`${remaining}`, (remaining > 1 ? getLocalizedString('device.plural', 'devices') : getLocalizedString('device', 'device'))]);
            } else {
                return getLocalizedString('viewDevices.activeDeviceLimit.noneRemaining', 'No more devices can be activated');
            }
        } else {
            return '';
        }
    }, [activeCount, maxActiveCount]);
    
    const isActiveDeviceLimitEnabled = React.useMemo(() => {
        return context.currentBrand?.activeDeviceLimit && context.currentLocation?.activeDeviceLimit;
    }, [context.currentBrand?.activeDeviceLimit, context.currentLocation?.activeDeviceLimit]);

    const getStatusTooltip = React.useCallback((device: DeviceDto) => {
        switch (device.status) {
            case DEV_STATUS_ACTIVE:
                return getLocalizedString('viewDevices.deviceStatus.active', 'This device is being actively monitored by ARC');
            case DEV_STATUS_PENDING:
                return (!appViewContext.brandView && isActiveDeviceLimitEnabled && (isAtActiveDeviceLimit || isOverActiveDeviceLimit)) ? getLocalizedString('viewDevices.deviceStatus.pending.full', 'If you want to activate this device, please first deactivate ' + numDevicesToDeactivate + (numDevicesToDeactivate > 1 ? ' devices' : ' device'), [numDevicesToDeactivate + (numDevicesToDeactivate > 1 ? ' devices' : ' device')]) : getLocalizedString('viewDevices.deviceStatus.pending', 'To make this Device active, add it to an ARC kiosk')
            case DEV_STATUS_INACTIVE:
                return (!appViewContext.brandView && isActiveDeviceLimitEnabled && (isAtActiveDeviceLimit || isOverActiveDeviceLimit)) ? getLocalizedString('viewDevices.deviceStatus.inactive.full', 'This device is no longer in use. If you want to activate it, please first deactivate ' + numDevicesToDeactivate + (numDevicesToDeactivate > 1 ? ' devices' : ' device'), [numDevicesToDeactivate + (numDevicesToDeactivate > 1 ? ' devices' : ' device')]) : getLocalizedString('viewDevices.deviceStatus.inactive', 'This Device is no longer in use. If you want to activate it, select “Reactivate” and add it to an ARC kiosk.')
            default:
                return undefined;
        }
    }, [appViewContext.brandView, numDevicesToDeactivate, isActiveDeviceLimitEnabled, isAtActiveDeviceLimit, isOverActiveDeviceLimit]);

    const showDeviceLimitAlert = React.useMemo(() => {
        return !!(!appViewContext.brandView && isActiveDeviceLimitEnabled && isOverActiveDeviceLimit && maxActiveCount > 0 && !context.hideDeviceLimitAlert);
    }, [appViewContext.brandView, isActiveDeviceLimitEnabled, context.hideDeviceLimitAlert, isOverActiveDeviceLimit, maxActiveCount])

    React.useEffect(() => {
        if(context.currentLocation?.locationId) {
            dispatch(getDeviceTypes({locationId: context.currentLocation.locationId, brandId: context.currentBrand?.brandId}));
        }
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch]);

    React.useEffect(() => {
        return () => {dispatch(deviceContextActions.resetGetDevices())}
    }, [dispatch]);

    React.useEffect(() => {
        if(state?.successmessage) {
            setSuccessMessage(state.successmessage);
        }
    }, [state]);

    const gridSize = React.useMemo(() => {
        let count : number = 4;

        let gridConfig: {template: string, rowPadding: string} = {
            template: '.5fr .5fr .5fr .5fr',
            rowPadding: '20px'
        }

        if (!isSmOrBelow) {
            if (deviceTypeContext.deviceTypes && deviceTypeContext.deviceTypes.length > 0) {
                count ++;
                gridConfig = {template: '.5fr .5fr .5fr .5fr .5fr', rowPadding: '10px'};
                if (bulkDeviceTypes.length > 0) {
                    gridConfig = {template: '.5fr .5fr .5fr .5fr .5fr .5fr', rowPadding: '10px'};
                }
            }
            if (appViewContext.brandView) {
                count ++;

                if(count === 6) {
                    gridConfig = {template: '.5fr .5fr .5fr .5fr .5fr .5fr', rowPadding: '10px'};
                } else {
                    gridConfig = {template: '.5fr .5fr .5fr .5fr .5fr', rowPadding: '10px'};
                }

            }
        }
        return gridConfig;
    },[isSmOrBelow, deviceTypeContext.deviceTypes, appViewContext.brandView, bulkDeviceTypes]);
    
    const bulkCheckboxTooltip = React.useCallback((device: DeviceDto) => {
        if (context.currentLocation?.serialIsDevice && device.deviceType?.serialIsDevice) {
            return getLocalizedString('viewDevices.bulkCheckbox.tooltip', 'The Update Device Type bulk task does not support updating devices using their Serial Number as their Device ID. Please update their Device Types individually.');
        }
        return '';
    }, [context.currentLocation?.serialIsDevice])

    const getDeviceActions = React.useCallback((device : DeviceDto) => {
        let actions = [];

        actions.push({
            value: `/devices/edit/locationId=${device.locationId}/deviceId=${device.deviceId}`,
            label: canEdit ? getLocalizedString('viewDevices.edit', 'Edit') : getLocalizedString('viewDevices.view', 'View'),
            icon: () => <UpdateOutlined/>
        });

        if(canEdit) {
            actions.push(isInactive(device) ? {
                value: `/devices/reactivate/locationId=${device.locationId}/deviceId=${device.deviceId}`,
                disabled: !canEdit,
                label: getLocalizedString('viewDevices.reactivate', 'Reactivate'),
                icon: () => <BatteryChargingFullOutlined/>
            } : {
                value: `/devices/deactivate/locationId=${device.locationId}/deviceId=${device.deviceId}`,
                disabled: !canEdit,
                label: getLocalizedString('viewDevices.deactivate', 'Deactivate'),
                icon: () => <PowerSettingsNew/>
            });
        }

        actions.push(...ReportList.map(report => {
                return {
                    value: `/reports/view/locationId=${context.currentLocation?.locationId}/lookerId=${report.lookerId}/deviceId=${device.deviceId ?? ''}`,
                    label: getLocalizedString('viewDevices.reports', 'Insights'),
                    icon: () => <AssessmentOutlined/>
                }
        }))

        return actions;

    }, [ReportList, canEdit, context.currentLocation?.locationId]);

    const RenderListItem: InfiniteScrollProps<DeviceDto>['renderItem'] = React.useCallback((device, index) => {

        return (
            device &&
            <div className={'device-row'} key={index} style={{backgroundColor: isInactive(device) ? LIGHTER_GREY : WHITE, gridTemplateColumns: gridSize.template}}>
                {bulkEnabled && canEdit &&
                    <Tooltip title={
                        bulkCheckboxTooltip(device) &&
                        <div style={{margin: '0', maxWidth: '600px'}}>
                            {bulkCheckboxTooltip(device)}
                        </div>
                    }>
                        <div className={'device-cell'}>
                            <ARCCheckbox
                                id={'device-bulk-checkbox' + index}
                                size={'md'}
                                disabled={context.currentLocation?.serialIsDevice && device.deviceType?.serialIsDevice}
                                style={{backgroundColor: theme.palette.primary.main}}
                                onChange={() => {
                                    if (!showBulkMenu) {
                                        setShowBulkMenu(true);
                                    }
                                    if (device.deviceId) {
                                        if (bulkDeviceMap[device.deviceId]) {
                                            const updatedBulkList = {...bulkDeviceMap};
                                            delete updatedBulkList[device.deviceId];
        
                                            setBulkDeviceMap(updatedBulkList);
                                        } else {
                                            setBulkDeviceMap({
                                                ...bulkDeviceMap,
                                                [device.deviceId]: !bulkDeviceMap[device.deviceId]
                                            });
                                        }
                                    }
                                }}
                                checked={!!bulkDeviceMap[device.deviceId ?? -1]}
                            />
                        </div>
                    </Tooltip>
                }
                <div className={'device-cell'}>{device.deviceTag}</div>
                <div className={'device-cell'}>{device.deviceIdNumber}</div>

                {!isSmOrBelow && (deviceTypeContext.deviceTypes && deviceTypeContext.deviceTypes.length > 0) &&
                <div className={'device-cell'}>{device.deviceType && device.deviceType.deviceTypeName}</div>
                }

                {appViewContext.brandView && !isSmOrBelow &&
                <div className={'device-cell'}>{context.authorizationDto?.authorizedLocations.find(l => l.locationId === device.locationId)?.locationName}</div>
                }

                <div className={'device-cell'} style={{overflow: 'unset', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <DropdownMenu
                        label={'...'}
                        isSmOrBelow={isSmOrBelow}
                        onChange={(option) => {
                            dispatch(deviceContextActions.setSelectedDevice(device));

                            navigate(option.value);
                        }}
                        style={{fontSize: 'min(1vh, 1vw) !important'}}
                        options={getDeviceActions(device)}
                    />
                </div>
                <div className={'device-cell'} style={{overflow: 'unset', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Tooltip placement="top" title={getStatusTooltip(device) ?? ''}>
                        <div>
                            <ARCBadge
                                label={getStatusDisplay(device)}
                                status={isActive(device) ? 'on' : isInactive(device) ? 'off' : 'standby'}
                                type={'status'}>
                            </ARCBadge>
                        </div>
                    </Tooltip>
                </div>
            </div>
        )
    }, [
        gridSize, 
        isSmOrBelow, 
        deviceTypeContext.deviceTypes, 
        appViewContext.brandView, 
        context.authorizationDto?.authorizedLocations, 
        getDeviceActions, 
        getStatusTooltip, 
        dispatch, 
        navigate,
        bulkDeviceMap,
        canEdit,
        theme.palette.primary.main,
        showBulkMenu,
        bulkEnabled,
        context.currentLocation?.serialIsDevice,
        bulkCheckboxTooltip
    ])

    const DeviceLimitAlert = React.useMemo(() => {
        return (
            showDeviceLimitAlert &&
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={showDeviceLimitAlert} style={{width: '80vw', maxWidth: '800px', minWidth: '200px', backgroundColor: RED, color: WHITE, padding: '15px', borderRadius: '5px'}}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex'}}>
                        <ErrorOutlined/>

                        <Typography style={{fontWeight: 'bold', marginLeft: '10px'}}>
                            {getLocalizedString('viewDevices.activeDeviceLimit.exceeded', 'Active Device Limit exceeded for this Location. Please deactivate ' + (excessDeviceCount ?? '') + ' device' + (excessDeviceCount > 1 ? 's' : '') + '.', [(excessDeviceCount ?? '') + ' ' + (excessDeviceCount > 1 ? getLocalizedString('device.plural', 'devices') : getLocalizedString('device', 'device')) + '.'])}
                        </Typography>
                    </div>

                    <div>
                        <CloseRounded style={{cursor: 'pointer'}} onClick={() => dispatch(appContextActions.setHideDeviceLimitAlert(true))} className="snackbar-close-icon" />
                    </div>
                </div>
            </Snackbar>
        );
    }, [dispatch, excessDeviceCount, showDeviceLimitAlert]);

    const DeviceUpdate = React.useMemo(() => {

        let devLink;
        if(state?.editingFromDeviceId) {
            if (state?.editDeviceFrom !== 'deactivate') {
                devLink =
                    <Link 
                        style={{
                            fontWeight: 'bold',
                            fontSize: calculatedFontSize('.8em', '1.2em'),
                            textDecoration: 'none'
                        }} 
                        target={'_self'}
                        to={`/devices/edit/locationId=${context.currentLocation?.locationId}/deviceId=${state.editingFromDeviceId}`}
                    >
                        {getLocalizedString('viewDevices.deviceLink', 'Device') + (state.editingFromDeviceIdNumber ? ' ' + state.editingFromDeviceIdNumber : '')}
                    </Link>
            } else {
                devLink =
                    <Link
                        style={{
                            fontWeight: 'bold',
                            fontSize: calculatedFontSize('.8em', '1.2em'),
                            textDecoration: 'none'
                        }} 
                        target={'_self'}
                        to={`/devices/edit/locationId=${context.currentLocation?.locationId}/deviceId=${state.editingFromDeviceId}`}
                    >
                        {getLocalizedString('viewDevices.deviceLink', 'Device') + (state.editingFromDeviceTag ? ' ' + state.editingFromDeviceTag : '')}
                    </Link>
            }
        } else {
            devLink = <span style={{fontSize: calculatedFontSize('.8em', '1.2em')}}>{getLocalizedString('viewDevices.deviceLink', 'Device')}</span>
        }

        let devMessage;
        if(state?.editDeviceFrom) {
            if (state.editDeviceFrom) {
                if (state.editDeviceFrom === 'create') {
                    if (state.createDeviceSuccessMessage) {
                        devMessage = <span style={{marginLeft: '5px', fontSize: calculatedFontSize('.8em', '1.2em')}}>{state.createDeviceSuccessMessage}</span>
                    } else {
                        devMessage = <span style={{marginLeft: '5px', fontSize: calculatedFontSize('.8em', '1.2em')}}>{getLocalizedString('viewDevices.saved', 'saved. This device can be activated at an ARC kiosk.')}</span>
                    }
                }
                if (state.editDeviceFrom === 'reactivate') {
                    devMessage = <span style={{marginLeft: '5px', fontSize: calculatedFontSize('.8em', '1.2em')}}>{getLocalizedString('viewDevices.reactivated', 'reactivated.')}</span>
                }
                if (state.editDeviceFrom === 'deactivate') {
                    devMessage = <span style={{marginLeft: '5px', fontSize: calculatedFontSize('.8em', '1.2em')}}>{getLocalizedString('viewDevices.deactivated', 'deactivated successfully.')}</span>
                }
            }
            return (
                <ServerMessage 
                    variant={'success'} 
                    closable={{
                        isOpen: successOpen,
                        onClose: () => {setSuccessOpen(false)}
                    }}
                >
                    <span style={{display: 'flex', width: '100%', flexWrap: 'wrap', alignItems: 'center'}}>
                        {devLink}
                        {devMessage}
                    </span>
                </ServerMessage>
            )
        }

        return null;
    }, [context.currentLocation?.locationId, 
              state?.editDeviceFrom, 
              state?.editingFromDeviceId, 
              state?.editingFromDeviceIdNumber, 
              state?.editingFromDeviceTag,
              state?.createDeviceSuccessMessage, 
              successOpen]);

    const listRef = React.useRef<List | null>(null);
    
    const [showBackToTop, setShowBackToTop] = React.useState<boolean>(false);
    const handleOnScroll = (props: ListOnScrollProps) => {
        if (props.scrollOffset > renderItemSize && !showBackToTop) {
            setShowBackToTop(true);
        } else if (props.scrollOffset <= renderItemSize && showBackToTop) {
            setShowBackToTop(false);
        }
    };
    
    const [listHasScrollbar, setListHasScrollbar] = React.useState<boolean>(false);
    
    const handleItemsRendered = (props : ListOnItemsRenderedProps) => {
        let visibleStopCount = props.visibleStopIndex + 1;
        if (visibleStopCount !== listRef.current?.props?.itemCount) {
            setListHasScrollbar(true);
        } else {
            setListHasScrollbar(false)
        }
        if (!deviceContext.getDevicesLoading && (listRef.current?.props?.itemCount ?? 0) > 0 && (visibleStopCount === listRef.current?.props?.itemCount)) {
            handleScrollToBottom()
        }
    };

    const handleScrollToBottom = () => {
        getDeviceData()
    };

    const getDeviceData = React.useCallback(() => {
        if (deviceContext.devicesListOffset >= 0) {
            dispatch(getDevicesByBrand({
                locationId: context.currentLocation?.locationId ?? -1,
                brandId: context.currentBrand?.brandId
            }));
        }
    }, [context.currentBrand?.brandId,
              context.currentLocation?.locationId,
              dispatch,
              deviceContext.devicesListOffset])

    const searchFilter = React.useCallback((newSortBy?, newOrder?, newSearchCriteria?) => {
        const sc = newSearchCriteria ?? searchCriteria;
        let searchOrder = newOrder ?? order;
        let searchSortBy = newSortBy ?? sortBy;

        let filterDto = {
            isAsc: searchOrder === 'asc',
            deviceTag: sc.filters.deviceTag,
            deviceIdNumber: sc.filters.deviceIdNumber,
            orderByString: searchSortBy,
            status: sc.status,
        }
        if (!isFilterEqual(deviceContext.deviceFilters, filterDto)) {
            dispatch(deviceContextActions.setDeviceFilter(filterDto));
            dispatch(getDevicesByBrand({
                locationId: context.currentLocation?.locationId ?? -1,
                brandId: context.currentBrand?.brandId
            }));
        }
    },[searchCriteria, order, sortBy, deviceContext.deviceFilters, dispatch, context.currentLocation?.locationId, context.currentBrand?.brandId])
    
    const registerDeviceDisabledReason = React.useMemo(() => {
        if (SAMS_BRANDS.find(id => id === context.currentBrand?.brandId) || WALMART_BRANDS.find(id => id === context.currentBrand?.brandId)) {
            if (context.currentLocation?.serialIsDevice) {
                return (
                    <div>
                        <span>
                            {getLocalizedString('viewDevices.addDevice.disabled.samsOne', 'New devices can now only be registered at an ARC kiosk.')}
                        </span>
                        <br/>
                        <br/>
                        <span>
                            {getLocalizedString('viewDevices.addDevice.disabled.samsTwo', 'To do this, log into Manager Tools and select “Devices". From there, select “Activate Device”.')}
                        </span>
                    </div>
                )
            }
        }
        if (appViewContext.brandView) {
            return getLocalizedString('viewDevices.addDevice.disabled', 'Please select a location to use the Register Device feature.')
        }
    }, [appViewContext.brandView, context.currentLocation?.serialIsDevice, context.currentBrand?.brandId])

    return (
        <AppWithFooter>
            <AppBar/>
                <SideBar/>
                <main className={'o-main'}>
                    <div className={'l-wrap l-container--lg'}>
                        {successMessage &&
                            <ServerMessage variant={'success'}>
                                <SuccessMessageSectionItem>
                                    {successMessage}
                                </SuccessMessageSectionItem>
                            </ServerMessage>
                        }

                        {deviceContext.getDevicesErrorMessage &&
                        <ServerMessage variant={'error'}>
                            <ErrorMessageSectionItem>
                                {deviceContext.getDevicesErrorMessage}
                            </ErrorMessageSectionItem>
                        </ServerMessage>
                        }
                        {deviceContext.bulkActionResponse && deviceContext.bulkActionSuccessful &&
                            <ServerMessage variant={'success'}>
                                <SuccessMessageSectionItem>
                                    {deviceContext.bulkActionResponse.message}
                                </SuccessMessageSectionItem>
                            </ServerMessage>
                        }
                        {deviceContext.bulkActionResponse && !deviceContext.bulkActionSuccessful &&
                            <ServerMessage variant={'error'}>
                                <ErrorMessageSectionItem>
                                    {deviceContext.bulkActionResponse.errorMessage}
                                </ErrorMessageSectionItem>
                            </ServerMessage>
                        }

                        {DeviceUpdate}
        
                        <FormTitle variant='h5' className={'o-heading--xl'}>{getLocalizedString('viewDevices.title', 'Device Roster (' + (deviceContext.totalDevices ?? 0) + ')', [`${deviceContext.totalDevices ?? 0}`])}</FormTitle>
        
                        <div className={'devices__top-actions'}>
                            {registerDeviceDisabledReason ?
                                <Tooltip placement="top" title={registerDeviceDisabledReason}>
                                    <span>
                                        <ARCButton
                                            id={'view-devices-create-device-button'}
                                            fill={'filled'}
                                            variant={'primary'}
                                            size={'md'}
                                            disabled={!!registerDeviceDisabledReason || !canEdit}
                                            onClick={() => {navigate(`/devices/create/locationId=${context.currentLocation?.locationId}`)}}
                                            label={getLocalizedString('viewDevices.addDevice', 'Register New Device')}
                                            icon={'add-device'}
                                        >
                                        </ARCButton>
                                    </span>
                                </Tooltip>
                                :
                                <Tooltip placement="top" title={getLocalizedString('viewDevices.addDevice.tooltip', 'Register new devices here. Once registered, a device is ready to be added to an ARC kiosk.')}>
                                    <span>
                                        <ARCButton
                                            id={'view-devices-create-device-button'}
                                            fill={'filled'}
                                            variant={'primary'}
                                            size={'md'}
                                            disabled={!!registerDeviceDisabledReason || !canEdit}
                                            onClick={() => {navigate(`/devices/create/locationId=${context.currentLocation?.locationId}`)}}
                                            label={getLocalizedString('viewDevices.addDevice', 'Register New Device')}
                                            icon={'add-device'}
                                        >
                                        </ARCButton>
                                    </span>
                                </Tooltip>
                            }
                            {(bulkEnabled) &&
                                <Button onClick={() => setShowBulkMenu(!showBulkMenu)}
                                        style={{color: showBulkMenu ? BLUE : BLACK, marginLeft: '45px'}}
                                >
                                    {showBulkMenu ?
                                        <ArrowDropUp/>
                                        :
                                        <ArrowDropDown/>
                                    }
                                    <span className={'sub-header primary'}>
                                        {getLocalizedString('viewEmployees.createBulkTask', 'Create Bulk Task')}
                                    </span>
                                </Button>
                            }
                        </div>
                        
                        {bulkEnabled && showBulkMenu &&
                            <BulkDeviceAction bulkDeviceList={bulkDeviceMap} resetBulkList={resetBulkDevice} deviceTypeList={bulkDeviceTypes}/>
                        }
        
                        <ScalableForm
                            onSubmit={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <ScalableFormControl fullWidth>
                                <div className={'devices__input__root'}>
                                    <ScalableGrid container spacing={1}>
        
                                        <CenterGrid style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} item>
                                            <span>
                                                {getLocalizedString('viewDevices.filter.location.label', 'Location Name')}
                                            </span>
                                            <div className={'devices__input__div'}>
                                                <LocationSelect id={'view-devices-location-filter-input'} showAllLocations={true}/>
                                                <CancelIconContainer/>
                                            </div>
                                        </CenterGrid>
        
                                        <CenterGrid style={{alignItems: 'flex-end'}} item>
                                            <div className={'devices__input__div'}>
                                                <ARCTextFilterInput
                                                    placeholder={getLocalizedString('viewDevices.filter.deviceTag', 'Serial Number')}
                                                    id="view-devices-device-tag-filter-input"
                                                    style={{width: calculateResponsiveSizeWithMinMax('30', '240', '200'), minHeight: '40px', paddingTop: '2px'}}
                                                    onSearch={searchFilter}
                                                    onChange={(event) => {setSearchCriteria({
                                                        ...searchCriteria,
                                                        filters: {
                                                            ...searchCriteria.filters,
                                                            deviceTag: event.target.value
                                                        }
                                                    });}}
                                                    onClear={() => {
                                                        const newSearchCriteria = {
                                                            ...searchCriteria,
                                                            filters: {
                                                                ...searchCriteria.filters,
                                                                deviceTag: ''
                                                            }
                                                        }
                                                        setSearchCriteria(newSearchCriteria);
                                                        searchFilter(sortBy, order, newSearchCriteria)
                                                    }}
                                                    value={searchCriteria.filters.deviceTag}
                                                />
                                            </div>
                                        </CenterGrid>
        
        
                                        <CenterGrid style={{alignItems: 'flex-end'}} item>
                                            <div className={'devices__input__div'}>
                                                <ARCTextFilterInput
                                                    placeholder={getLocalizedString('viewDevices.filter.deviceIdNumber', 'Device ID')}
                                                    style={{width: calculateResponsiveSizeWithMinMax('30', '240', '200'), minHeight: '40px', paddingTop: '2px'}}
                                                    id="view-devices-device-id-filter-input"
                                                    onSearch={searchFilter}
                                                    onChange={(event) => {setSearchCriteria({
                                                        ...searchCriteria,
                                                        filters: {
                                                            ...searchCriteria.filters,
                                                            deviceIdNumber: event.target.value
                                                        }
                                                    });}}
                                                    onClear={() => {
                                                        const newSearchCriteria = {
                                                            ...searchCriteria,
                                                            filters: {
                                                                ...searchCriteria.filters,
                                                                deviceIdNumber: ''
                                                            }
                                                        }
                                                        setSearchCriteria(newSearchCriteria);
                                                        searchFilter(sortBy, order, newSearchCriteria)
                                                    }}
                                                    value={searchCriteria.filters.deviceIdNumber}
                                                />
        
                                            </div>
                                        </CenterGrid>
                                        { !appViewContext.brandView && !isSmOrBelow &&
                                            <CenterGrid style={{alignItems: 'flex-end'}} item>
                                                <HiddenOnMobileDiv>
                                                    <div className={'devices__input__div'}>
                                                        <FormControl>
                                                            <ARCSelectFilterInput
                                                                id="view-devices-status-filter-input"
                                                                style={{outline: 'none', width: calculateResponsiveSizeWithMinMax('30', '240', '200'), minHeight: '40px',}}
                                                                value={StatusFilterValue}
                                                                options={[
                                                                    {value: '', label: getLocalizedString('viewDevices.filter.status.all', 'All Statuses')},
                                                                    {value: DEV_STATUS_ACTIVE, label: getLocalizedString('viewDevices.filter.status.active', 'Active')},
                                                                    {value: DEV_STATUS_PENDING, label: getLocalizedString('viewDevices.filter.status.pending', 'Pending Activation')},
                                                                    {value: DEV_STATUS_INACTIVE, label: getLocalizedString('viewDevices.filter.status.inactive', 'Deactivated')}
                                                                ]}
                                                                onChange={(option) => {
                                                                    const newSearchCriteria = {
                                                                        ...searchCriteria,
                                                                        filters: {
                                                                          ...searchCriteria.filters
                                                                        },
                                                                        status: option.value !== '' ? option.value as DEVICE_STATUS : undefined
                                                                    }
                                                                    setSearchCriteria(newSearchCriteria);
                                                                    searchFilter(sortBy, order, newSearchCriteria);
                                                                }}
                                                                onClear={() => {
                                                                    const newSearchCriteria = {
                                                                        ...searchCriteria,
                                                                        filters: {
                                                                            ...searchCriteria.filters
                                                                        },
                                                                        status: undefined
                                                                    }
                                                                    setSearchCriteria(newSearchCriteria);
                                                                    searchFilter(sortBy, order, newSearchCriteria)
                                                                }}
                                                            />
                                                        </FormControl>
        
                                                    </div>
                                                </HiddenOnMobileDiv>
                                            </CenterGrid>
                                        }
                                    </ScalableGrid>
        
                                </div>
        
                                {(!appViewContext.brandView && isActiveDeviceLimitEnabled && maxActiveCount) ?
                                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                                        <ProgressBar currentValue={activeCount}
                                                     maxValue={maxActiveCount}
                                                     warningValue={maxActiveCount - 3}
                                                     label={getActiveLimitMessage}
                                                     toolTip={
                                                         {
                                                             title: getLocalizedString('viewDevices.activeDeviceLimit.tooltip.title', 'ACTIVE DEVICE LIMIT'),
                                                             text: [getLocalizedString('viewDevices.activeDeviceLimit.tooltip.text', 'The location has a maximum number of Devices that can be active at any given time across all ARC kiosks.\n Once the maximum Active Devices is reached, a manager must remove and deactivate devices before adding new ones.\n')]
                                                         }
                                                     }/>
                                    </div>
                                    :
                                    null
                                }
                            </ScalableFormControl>
                        </ScalableForm>
        
                        <div className={'device-grid-table'}>
                            <div className={'device-header-div'} style={{
                                borderBottom: '2px solid black',
                                gridTemplateColumns: gridSize.template,
                                paddingRight: listHasScrollbar ? '10px' : '0px'
                            }}>
                                {bulkEnabled && canEdit &&
                                    <div className={'device-header device-cell'}>
                                        <HeaderTableContent style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                            <Checkbox
                                                indeterminate={Object.keys(bulkDeviceMap).length > 0 && Object.keys(bulkDeviceMap).length < (sortedList?.length ?? 0)}
                                                indeterminateIcon={<UndeterminedCheckboxIcon/>}
                                                onClick={() => {
                                                    if (!showBulkMenu) {
                                                        setShowBulkMenu(true);
                                                    }
            
                                                    if(Object.keys(bulkDeviceMap).length > 0) {
                                                        setBulkDeviceMap({});
                                                    } else {
                                                        const updatedBulkList = {...bulkDeviceMap};
            
                                                        sortedList?.forEach(d => {
                                                            if (d.deviceId) {
                                                                if (!(context.currentLocation?.serialIsDevice && d.deviceType?.serialIsDevice)) {
                                                                    updatedBulkList[d.deviceId] = true;
                                                                }
                                                            }
                                                        });
            
                                                        setBulkDeviceMap(updatedBulkList);
                                                    }
                                                }}
                                                checked={(sortedList?.length ?? 0) > 0 && Object.keys(bulkDeviceMap).length === (sortedList?.length ?? 0)}
                                                size={'small'}
                                                id={'view-devices-bulk-task-select-all-checkbox'}
                                                color="primary"/>
                                                {getLocalizedString('viewEmployees.bulkTask.selectAll', 'All')}
                                        </HeaderTableContent>
                                    </div>
                                }
                                <div className={'device-header device-cell'} onClick={() => {
                                    let newOrder: 'desc' | 'asc' = order === 'asc' && sortBy === DEVICE_ORDER_BY_TYPES.SERIAL_NUMBER ? 'desc' : 'asc'
                                    setOrder(newOrder);
                                    setSortBy(DEVICE_ORDER_BY_TYPES.SERIAL_NUMBER);
                                    searchFilter(DEVICE_ORDER_BY_TYPES.SERIAL_NUMBER, newOrder);
                                }}>
                                    <HeaderTableContent
                                        style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                        {getLocalizedString('viewDevices.filter.deviceTag', 'Serial Number')}
                                        {sortBy === DEVICE_ORDER_BY_TYPES.SERIAL_NUMBER ? order === 'asc' ? <ArrowUpward/> :
                                            <ArrowDownward/> : <ArrowUpward style={{visibility: 'hidden'}}/>}
                                    </HeaderTableContent>
                                </div>
        
                                <div className={'device-header device-cell'} onClick={() => {
                                    let newOrder: 'desc' | 'asc' = order === 'asc' && sortBy === DEVICE_ORDER_BY_TYPES.DEVICE_ID ? 'desc' : 'asc'
                                    setOrder(newOrder);
                                    setSortBy(DEVICE_ORDER_BY_TYPES.DEVICE_ID);
                                    searchFilter(DEVICE_ORDER_BY_TYPES.DEVICE_ID, newOrder);
                                }}>
                                    <HeaderTableContent
                                        style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                        {getLocalizedString('viewDevices.filter.deviceIdNumber', 'Device ID')}
                                        {sortBy === DEVICE_ORDER_BY_TYPES.DEVICE_ID ? order === 'asc' ? <ArrowUpward/> :
                                            <ArrowDownward/> : <ArrowUpward style={{visibility: 'hidden'}}/>}
                                    </HeaderTableContent>
                                </div>
        
                                {!isSmOrBelow && (deviceTypeContext.deviceTypes && deviceTypeContext.deviceTypes.length > 0) &&
                                    <div className={'device-header device-cell'} onClick={() => {
                                        let newOrder: 'desc' | 'asc' = order === 'asc' && sortBy === DEVICE_ORDER_BY_TYPES.DEVICE_TYPE ? 'desc' : 'asc'
                                        setOrder(newOrder);
                                        setSortBy(DEVICE_ORDER_BY_TYPES.DEVICE_TYPE);
                                        searchFilter(DEVICE_ORDER_BY_TYPES.DEVICE_TYPE, newOrder);
                                    }}>
                                        <HeaderTableContent
                                            style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                            {getLocalizedString('viewDevices.filter.deviceType', 'Device Type')}
                                            {sortBy === DEVICE_ORDER_BY_TYPES.DEVICE_TYPE ? order === 'asc' ?
                                                <ArrowUpward/> :
                                                <ArrowDownward/> : <ArrowUpward style={{visibility: 'hidden'}}/>}
                                        </HeaderTableContent>
                                    </div>
                                }
        
                                {!isSmOrBelow && appViewContext.brandView &&
                                    <div className={'device-header device-cell'} onClick={() => {
                                    }}>
                                        <HeaderTableContent
                                            style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                            {getLocalizedString('viewDevices.filter.location', 'Location')}
                                        </HeaderTableContent>
                                    </div>
                                }
        
                                <div className={'device-header device-cell'}>
                                    <HeaderTableNonSortable style={{
                                        textAlign: 'center',
                                        fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')
                                    }}>
                                        {getLocalizedString('viewDevices.actions', 'Actions')}
                                    </HeaderTableNonSortable>
                                </div>
                                <div className={'device-header device-cell'}>
                                    <HeaderTableNonSortable style={{
                                        textAlign: 'center',
                                        fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')
                                    }}>
                                        {getLocalizedString('viewDevices.status', 'Status')}
                                    </HeaderTableNonSortable>
                                </div>
                            </div>
                            <div className={'device-body-div'}>
                                <InfiniteScrollList<DeviceDto>
                                    initialHeight={renderItemSize}
                                    data={sortedList}
                                    renderItem={RenderListItem}
                                    padding={0}
                                    onItemsRendered={handleItemsRendered}
                                    onScroll={handleOnScroll}
                                    listRef={listRef}
                                />
                                {deviceContext.getDevicesLoading &&
                                    <div className={'device-loading-div'}>
                                        <CircularProgress style={{marginTop: '10vh'}} size={100}/>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            showBackToTop ?
                            <div className={'u-display--flex u-align-items--center u-justify-content--center'}>
                                <ARCButton
                                    size={'md'}
                                    variant={'secondary'}
                                    fill={'outlined'}
                                    icon={'arrow-up'}
                                    label={getLocalizedString('viewDevices.toTop', 'TO TOP')}
                                    onClick={() => {listRef.current?.scrollTo(0)}}
                                >
                                </ARCButton>
                            </div>
                            :
                            undefined
                        }
        
                        {DeviceLimitAlert}
                    </div>
                </main>
        </AppWithFooter>
    );
};

const CenterGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px'
});

const HeaderTableContent = styled(Typography) (({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    // width: '100%',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: calculatedFontSize('1em', '1.45em'),
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: calculatedFontSize('.75em', '1.25em'),
    }
}));

const HeaderTableNonSortable = styled(Typography) (({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontFamily: "Proxima Nova",
    fontSize: calculatedFontSize('1em', '1.45em'),
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: calculatedFontSize('.75em', '1.25em'),
    }
}));
