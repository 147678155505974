import EN_BUNDLE from '../resources/messages_en.json';
import FR_BUNDLE from '../resources/messages_fr.json';
import DEFAULT_BUNDLE from '../resources/messages.json';
import {locale, LOCALE_EN_US, LOCALE_FR_CA, locale_type} from "../dtos/API_DOMAINS";

const IMAGE_DIR = '/img/locales';

export function getLocalizedString(key: string, defaultValue?: string, params?: string[]) {
    let bundle : {[key: string]: string};

    let usedLocale = getLocale();
    switch(usedLocale) {
        case LOCALE_EN_US:
            bundle = EN_BUNDLE;
            break;
        case LOCALE_FR_CA:
            bundle = FR_BUNDLE;
            break;
        default:
            bundle = DEFAULT_BUNDLE;
    }


    let translation = bundle[key];

    if (translation && params) {
        translation = translation.replace(/{\d+}/g, (match) => {
            const paramIndex = parseInt(match.substring(1, match.length - 1));
            if (paramIndex >= 0 && paramIndex < params.length) {
                return params[paramIndex];
            } else {
                return match;
            }
        });
    }

    return translation ?? defaultValue;
}

export function getLocalizedImage(imageName: string) {
    let localeDir : string = '';
    let usedLocale = getLocale();

    switch(usedLocale) {
        case LOCALE_EN_US:
            localeDir = 'en';
            break;
        case LOCALE_FR_CA:
            localeDir = 'fr';
            break;
        default:
            localeDir = 'en';
    }

    return IMAGE_DIR + '/' + localeDir + '/' + imageName;
}

export function getLocale() {
    if(!window.locale) {
        const prefLocale = localStorage.getItem("userPrefLocale");
        const defaultLocale = localStorage.getItem("defaultLocale") || locale;

        window.locale = (prefLocale || defaultLocale) as locale_type;
    }

    return window.locale;
}