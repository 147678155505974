import * as React from 'react';
import AppBar from "../appbar/AppBar";
import {SideBar} from "../sidebar/SideBar";
import {FormTitle} from "../ui-components/StyledComponents";
import {AppWithFooter} from "../../App";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {appContextSelector} from "../app-context/appContextSlice";
import {Tooltip} from '@material-ui/core';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {selectLocationThunk} from "../app-context/thunks/SelectLocationThunk";
import {ServerMessage} from "../ui-components/ServerMessage";
import {LookerEmbedSDK} from "@looker/embed-sdk";
import { ReportOption } from './ReportTypes';
import {reportContextActions} from "./reportSlice";
import {
    ALL_LOOKER_DASHBOARDS,
    DAILY_DASHBOARD, DEFAULT_TOOLTIP,
    KPI_DASHBOARD_2_6,
    KPI_DASHBOARD_7_PLUS
} from "../../dtos/Report";
import {Info} from "@material-ui/icons";
import {TemporaryMessage} from "../ui-components/TemporaryMessage";
import {getLocalizedString} from "../../util/Localization";
import {AllLocationsDto} from "../../dtos/Location";
import {injectURLParamsIntoPathAndNavigate} from "../../util/Navigation";

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId} = useParams();

    React.useEffect(() => {
        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));
        }
    }, [context.currentLocation?.locationId, dispatch, locationId]);
}

export const ReportsMenu = () => {
    useAutoSelectLocationByUrl();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const {lookerId, employeeId, deviceId} = useParams();

    React.useEffect(() => {
        if(employeeId) {
            dispatch(reportContextActions.setEmployeeId(parseInt(employeeId)));
        } else if(deviceId) {
            dispatch(reportContextActions.setDeviceId(parseInt(deviceId)));
        }
    }, [deviceId, dispatch, employeeId]);

    //This variable handles the report that initially loads when the page first renders
    //From the initial dashboards the user may navigate to different dashboards within the iFrame
    const [selectedReport, setSelectedReport] = React.useState<ReportOption | undefined>(undefined);
    //This variable handles updating display information as the user navigates between dashboards
    //within the iFrame
    const [displayReport, setDisplayReport] = React.useState<ReportOption | undefined>(undefined);
    const [getEmbedUrlError, setGetEmbedUrlError] = React.useState<string>('');
    const [showMessage, setShowMessage] = React.useState<boolean>(false);

    const defaultReport = React.useMemo<ReportOption>(() => {
        if(context.authorizationDto && context.currentBrand) {
            const brandLocationCount = context.authorizationDto.authorizedLocations.filter(l => l.brand.brandId === context.currentBrand?.brandId).length;
            const authorizedLocationCount = context.authorizationDto.authorizedLocations?.length;

            if(brandLocationCount < 3 || authorizedLocationCount < 3) {
                return DAILY_DASHBOARD
            } else if (brandLocationCount < 7 || authorizedLocationCount < 7) {
                return KPI_DASHBOARD_2_6;
            } else if (brandLocationCount > 6 || authorizedLocationCount > 6) {
                return KPI_DASHBOARD_7_PLUS;
            } else {
                return DAILY_DASHBOARD;
            }
        } else {
            return DAILY_DASHBOARD;
        }
    }, [context.authorizationDto, context.currentBrand]);

    React.useEffect(() => {
        if(lookerId) {
            const URLDashboard = ALL_LOOKER_DASHBOARDS.find(r => r.lookerId === parseInt(lookerId));
            if (URLDashboard) {
                setSelectedReport(URLDashboard);
                setDisplayReport(URLDashboard);
            }
        } else {
            setSelectedReport(defaultReport);
            setDisplayReport(defaultReport);
        }
    }, [context.authorizationDto, defaultReport, lookerId]);

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const params = useParams();

    const lastLoadedDashboardRef = React.useRef<string | null>(null); // Keep track of the last dashboard we loaded

    const makeDashboard = React.useCallback((el) => {
        const dashboardId = `${selectedReport?.lookMLDashboardId}`;

        if (!el || lastLoadedDashboardRef.current === dashboardId) return; // Prevent reinitialization
        lastLoadedDashboardRef.current = dashboardId;

        el.innerHTML = "";
        LookerEmbedSDK.createDashboardWithId(dashboardId)
            // adds the iframe to the DOM as a child of a specific element
            .appendTo(el)
            .withDynamicIFrameHeight()
            .withDialogScroll()
            // the .on() method allows us to listen for and respond to events inside the iframe. See here for a list of events: https://docs.looker.com/reference/embedding/embed-javascript-events
            .on("dashboard:run:start", (e) => {
                window.scrollTo(0, 0);
                const dbId = e.dashboard.id;
                const report = ALL_LOOKER_DASHBOARDS.find(r => r.lookMLDashboardId === dbId);

                if (report) setDisplayReport(report);

                const filteredLocation = e.dashboard.dashboard_filters["Location Name"];

                if (filteredLocation && !filteredLocation.includes(",")) {
                    const authLocation = context.authorizationDto?.authorizedLocations?.find(loc => loc.locationName === filteredLocation);
                    if (authLocation) {
                        injectURLParamsIntoPathAndNavigate(pathname, params, [{targetParamName: 'locationId', newValue: authLocation.locationId}], navigate);
                    }
                } else {
                    injectURLParamsIntoPathAndNavigate(pathname, params, [{targetParamName: 'locationId', newValue: AllLocationsDto.locationId}], navigate);
                }
            })
            .on("dashboard:run:complete", (e) => {
                setShowMessage(true);
                console.log(e);
            })
            // this line performs the call to the auth service to get the iframe's src='' url, places it in the iframe and the client performs the request to Looker
            .build()
            // this establishes event communication between the iframe and parent page
            .connect()
            // This takes the dashboard's reference and assigns it to a variable defined earlier.
            // It will be used for sending JavaScript events to the dashboard iframe.
            .then((dashboardReference) => {
                const lookerFrameDiv = document.getElementById('looker-frame-div');
                if (lookerFrameDiv) {
                    const iframe = lookerFrameDiv.querySelector('iframe');
                    if (iframe) {
                        iframe.style.width = '84vw';
                    }
                }
            })
            // catch various errors which can occur in the process (note: does not catch 404 on content)
            .catch((error) => {
                console.error("An unexpected error occurred", error);
                setGetEmbedUrlError(getLocalizedString('reports.error', 'Unable to load reports. Please try again later.'));
            });
    }, [context.authorizationDto?.authorizedLocations, navigate, params, pathname, selectedReport?.lookMLDashboardId]);

    React.useEffect(() => {
        return () => {
            dispatch(reportContextActions.clearState());
        }
    }, [dispatch]);

    return (
        <AppWithFooter>
            <AppBar static={true}/>
            <SideBar/>
            <main className={'o-main'}>
                <div className={'l-wrap l-container--lg'}>
                    {getEmbedUrlError &&
                        <ServerMessage variant={'error'}>
                            {getEmbedUrlError}
                        </ServerMessage>
                    }

                    <div style={{width:'100%', display: 'flex', alignItems: 'center'}}>
                        <FormTitle style={{width:'unset', marginRight: '10px'}}>
                            {displayReport ? getLocalizedString(displayReport.displayName) : getLocalizedString('reports.title', 'REPORTS')}
                        </FormTitle>

                        <div style={{height: '40px'}}>

                        <Tooltip placement={'right-start'} title={
                            (displayReport?.tooltipText ?? DEFAULT_TOOLTIP).map(tip => {
                                return (
                                    <div style={{maxWidth: '350px'}}>
                                        <b>{getLocalizedString(tip.title)}</b>
                                        <br/>
                                        <span>{getLocalizedString(tip.description)}</span>
                                        <br/>
                                        <br/>
                                    </div>
                                )
                            })
                        }>
                            <Info color={'primary'}/>
                        </Tooltip>

                        </div>

                        {/*{!loadingFinished && selectedReport ? <CircularProgress /> : null}*/}
                    </div>

                    <FormTitle style={{width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <TemporaryMessage style={{display: 'none'}} id={'dashboard-loaded-message'} message={getLocalizedString('reports.loaded', 'Dashboard Loaded!')} delay={3000} show={showMessage} onHide={() => setShowMessage(false)}/>
                    </FormTitle>
                    
                    {selectedReport &&
                        <div id={'looker-frame-div'} ref={makeDashboard}/>
                    }
                </div>
            </main>
        </AppWithFooter>
    )
}
