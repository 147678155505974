import {LocationDto} from "./Location";
import {DeviceIssueDto} from "./Device";
import {env} from "./API_DOMAINS";

export const SOURCE_CIS = 'CIS';
export const SOURCE_BOTH = 'BOTH';
export const SOURCE_BRAND = 'BRAND';

export const SAMS_BRANDS : number[] = env === 'qa' ? [10007] : env === 'production' ? [10045, 10001] : [];
export const WALMART_BRANDS : number[] = env === 'qa' ? [10269] : env === 'production' ? [10069] : [10175];

export function isMDCDisabledBrand(brandId: number | undefined) {
    if(brandId) {
        return !!(SAMS_BRANDS.find(id => id === brandId) || WALMART_BRANDS.find(id => id === brandId));
    }

    return false;
}

export enum DeviceNumberComposition {
    NUMERIC = 'NUMERIC',
    ALPHANUMERIC = 'ALPHANUMERIC'
} 

export enum EmployeeNumberComposition {
    NUMERIC = 'NUMERIC',
    ALPHANUMERIC = 'ALPHANUMERIC'
} 
export interface BrandDto {
    brandId: number;
    brandName: string;
    badgeValidation: boolean;
    employeeAPIEnabled: boolean;
    employeeNumberSource: string;
    employeeNumberPrefix: string;
    deviceNumberSource: string;
    deviceNumberPrefix: string;
    locationCount: number;
    employeeNumberLengthMin: number;
    employeeNumberLengthMax: number;
    deviceNumberLength: number;
    deviceNumberLengthMin: number;
    deviceNumberLengthMax: number;
    deviceNumberComposition?: DeviceNumberComposition;
    employeeNumberComposition?: EmployeeNumberComposition;
    authorizedLocations: LocationDto[];
    brandDeviceIssues?: DeviceIssueDto[];
    allowMultipleCheckouts: boolean;
    activeDeviceLimit: boolean;
    onboardingImageFile1: string;
    onboardingImageFile2: string;
    onboardingImageFile3: string;
    onboardingImageFile4: string;
    onboardingImageFile5: string;
}

export const hasOnboardingMedia = (brand: BrandDto) => {
    return !!brand.onboardingImageFile1 || !!brand.onboardingImageFile2 || !!brand.onboardingImageFile3 || !!brand.onboardingImageFile4 || !!brand.onboardingImageFile5
}

export const isEmpNumCisProvided = (brand : BrandDto) => {
    return brand.employeeNumberSource === SOURCE_CIS;
}

export const isDevNumCisProvided = (brand : BrandDto) => {
    return brand.deviceNumberSource === SOURCE_CIS || brand.deviceNumberSource === SOURCE_BOTH;
}
